var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-segment-handler","sidebar-class":"sidebar-lg","visible":true,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":_vm.toggleSidebarHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.modeloId ? "Editar" : "Cadastrar")+" Versão")]),_c('div',[_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.formValidation.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Código","label-for":"codigo"}},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('b-form-input',{attrs:{"id":"codigo","autofocus":"","state":_vm.formValidation.getValidationState(validationContext),"trim":"","disabled":_vm.isLoading,"readonly":""},on:{"keypress":_vm.limitDigits},model:{value:(_vm.nomenclatureLocal.Codigo),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "Codigo", $$v)},expression:"nomenclatureLocal.Codigo"}}):_vm._e(),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Marca","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Marca","label-for":"marca","state":_vm.formValidation.getValidationState(validationContext)}},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.fabricanteOptions,"reduce":_vm.reduceOptionsValue,"label":"label","input-id":"marca","disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.nomenclatureLocal.FabricanteId),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "FabricanteId", $$v)},expression:"nomenclatureLocal.FabricanteId"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Modelo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Modelo","label-for":"modelo","state":_vm.formValidation.getValidationState(validationContext)}},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.modelOptions,"reduce":_vm.reduceOptionsValue,"label":"label","input-id":"modelo","disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.nomenclatureLocal.ModeloId),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "ModeloId", $$v)},expression:"nomenclatureLocal.ModeloId"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Versão do veículo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Versão do veículo","label-for":"vehicleVersion","state":_vm.formValidation.getValidationState(validationContext)},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true)},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"input-id":"vehicleVersion","state":_vm.formValidation.getValidationState(validationContext),"disabled":_vm.isLoading},model:{value:(_vm.nomenclatureLocal.Nome),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "Nome", $$v)},expression:"nomenclatureLocal.Nome"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Descrição Original","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Descrição Original","label-for":"descricaoOriginal","state":_vm.formValidation.getValidationState(validationContext)},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true)},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":true,"input-id":"descricaoOriginal","state":_vm.formValidation.getValidationState(validationContext),"disabled":_vm.isLoading},model:{value:(_vm.nomenclatureLocal.DescricaoOriginal),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "DescricaoOriginal", $$v)},expression:"nomenclatureLocal.DescricaoOriginal"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Cilindradas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Cilindradas","label-for":"cilindradas","state":_vm.formValidation.getValidationState(validationContext)},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true)},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"input-id":"cilindradas","state":_vm.formValidation.getValidationState(validationContext),"disabled":_vm.isLoading},on:{"keypress":_vm.limitCilindradas},model:{value:(_vm.nomenclatureLocal.Cilindradas),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "Cilindradas", $$v)},expression:"nomenclatureLocal.Cilindradas"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Categoria","label-for":"categoria","state":_vm.formValidation.getValidationState(validationContext)}},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryOptions,"reduce":_vm.reduceOptionsValue,"label":"label","input-id":"categoria","disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.nomenclatureLocal.CategoriaVeiculoId),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "CategoriaVeiculoId", $$v)},expression:"nomenclatureLocal.CategoriaVeiculoId"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Segmento Associado"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Segmento Associado","label-for":"segmentoAssociado","state":_vm.formValidation.getValidationState(validationContext)}},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.segmentAssociadoOptions,"reduce":_vm.reduceOptionsValue,"clearable":true,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.nomenclatureLocal.SegmentoAssociadoId),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "SegmentoAssociadoId", $$v)},expression:"nomenclatureLocal.SegmentoAssociadoId"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Segmento Mercado"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Segmento Mercado","state":_vm.formValidation.getValidationState(validationContext)}},[(_vm.isLoading && !_vm.isSavingChanges)?_c('AvSkeletonInput'):_vm._e(),(!_vm.isLoading || _vm.isSavingChanges)?_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.segmentMercadoOptions,"reduce":_vm.reduceOptionsValue,"clearable":true,"disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.nomenclatureLocal.SegmentoId),callback:function ($$v) {_vm.$set(_vm.nomenclatureLocal, "SegmentoId", $$v)},expression:"nomenclatureLocal.SegmentoId"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":_vm.formValidation.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('av-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","loading":_vm.isSavingChanges,"disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.isSavingChanges ? "Salvando" : "Salvar")+" ")]),_c('av-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.$emit('updateIsSidebarActive', false)}}},[_vm._v(" Cancelar ")])],1)],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }