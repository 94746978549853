import store from "@/store";
import Vue from "vue";
import {
  IResponseGetSegment,
  IResponseGetSegmentTypes,
  IResponseListSegment,
  ISegmentCreateResponse,
  ISegmentDeleteResponse,
  ISegmentUpdateResponse,
  ISegmentUpdate,
  ISegmentCreate,
} from "@core/services/interfaces/vehicle/segment/ISegmentService";
import { ISegment } from "@core/services/interfaces/vehicle/segment/ISegmentService";
import { IBodyListSegment } from "@core/services/interfaces/vehicle/segment/IBodySearch";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Interfaces

export default function useSegmentHandler(ctx: Vue) {
  /**
   * Dispara a ação de criar segmento
   */
  const fetchCreateSegment = async (
    segment: ISegmentCreate
  ): Promise<ISegmentCreateResponse> => {
    return await store
      .dispatch("admin-nomenclature-segments/fetchCreateSegment", segment)
      .then((reponse): ISegmentCreateResponse => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Segmento inserido com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        return reponse;
      })
      .catch((response) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title:
              response.response.data.Errors[0] || "Erro ao inserir Segmento!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response;
      });
  };

  const fetchListSegments = async (
    body?: IBodyListSegment
  ): Promise<IResponseListSegment> => {
    return await store
      .dispatch("admin-nomenclature-segments/fetchListSegments", body)
      .then((reponse): IResponseListSegment => {
        return reponse;
      })
      .catch((response) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao inserir segmento!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response;
      });
  };
  /**
   * Dispara a ação de atualizar segmento
   */
  const fetchUpdateSegment = async (
    segment: ISegmentUpdate
  ): Promise<ISegmentUpdateResponse> => {
    return await store
      .dispatch("admin-nomenclature-segments/fetchUpdateSegment", {
        id: segment.id,
        nome: segment.nome,
        codigo: segment.codigo,
        ativo: segment.ativo,
        tipo: segment.tipo,
      })
      .then((response): ISegmentUpdateResponse => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Segmento atualizado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          ctx.$emit("refresh-data");
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        return response;
      })
      .catch((response): any => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title:
              response.response.data.Errors[0] || "Erro ao atualizar segmento!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response;
      });
  };

  /**
   * Dispara a ação de consultar segmento pelo ID
   */
  const fetchGetSegmentById = async (segmentId: number): Promise<ISegment> => {
    return await store
      .dispatch("admin-nomenclature-segments/fetchGetSegmentById", segmentId)
      .then((response): IResponseGetSegment => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          const segment = response.data.Data;
          return segment;
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Falha ao consultar os dados do segmento!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });

          return response;
        }
      })
      .catch((error): any => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao consultar o segmento!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return error;
      });
  };

  const fetchDeleteSegment = async (
    segmentId: number
  ): Promise<ISegmentDeleteResponse> => {
    return await store
      .dispatch("admin-nomenclature-segments/fetchDeleteSegment", segmentId)
      .then((response: ISegmentDeleteResponse): ISegmentDeleteResponse => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Segmento excluído com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        return response;
      })
      .catch((error: XMLHttpRequest): any => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: `${error.response.data.Errors[0]}`,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return error;
      });
  };
  const fetchGetSegmentTypes = async (): Promise<IResponseGetSegmentTypes> => {
    return await store
      .dispatch("admin-nomenclature-segments/fetchGetSegmentTypes")
      .then((response: IResponseGetSegmentTypes): IResponseGetSegmentTypes => {
        return response;
      })
      .catch((error: XMLHttpRequest): any => {
        return error;
      });
  };

  return {
    fetchCreateSegment,
    fetchUpdateSegment,
    fetchGetSegmentById,
    fetchListSegments,
    fetchDeleteSegment,
    fetchGetSegmentTypes,
  };
}
