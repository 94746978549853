import store from "@/store";
import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useBrand } from "@/services";

// Interfaces
import {
  IResponseGetBrandById,
  IResponseListBrands,
  IBrandCreateResponse,
  IBrandUpdateResponse,
  IBrandDeleteResponse,
  IBrandRead,
  IBrandWrite,
} from "@core/services/interfaces/vehicle/brands/IBrandService";

export default function useBrandHandler(ctx: Vue) {
  const fetchCreateBrand = async (
    brand: IBrandRead
  ): Promise<Array<IBrandRead>> => {
    return await store
      .dispatch("admin-brands/fetchCreateBrand", {
        nome: brand.Nome,
        codigo: brand.Codigo,
        ativo: brand.Ativo,
        ordem: brand.Ordem,
      })
      .then((response): IBrandCreateResponse => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Marca inserida com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        return response.data;
      })
      .catch((response) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao criar Marca!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response.data;
      });
  };

  const fetchListBrands = async (): Promise<Array<IBrandRead>> => {
    return await useBrand
      .requestList({ draw: 1 })
      .then((response: IResponseListBrands) => {
        return response.data.data;
      })
      .catch((response) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao recuperar marcas!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response.data.data;
      });
  };

  const fetchUpdateBrand = async (
    brand: IBrandRead
  ): Promise<IBrandUpdateResponse> => {
    return await store
      .dispatch("admin-brands/fetchUpdateBrand", {
        id: brand.Id,
        nome: brand.Nome,
        codigo: brand.Codigo,
        ativo: brand.Ativo,
        ordem: brand.Ordem,
      })
      .then((response): IBrandUpdateResponse => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Marca atualizada com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          ctx.$emit("refresh-data");
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        return response;
      })
      .catch((response): any => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao atualizar marca!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response;
      });
  };

  const fetchGetBrandById = async (brandId: number): Promise<IBrandRead> => {
    return await store
      .dispatch("admin-brands/fetchGetBrandById", brandId)
      .then((response): IResponseGetBrandById => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          const brand = response.data.Data;
          return brand;
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Falha ao consultar os dados da marca!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });

          return response.data.Data;
        }
      })
      .catch((error: XMLHttpRequest): any => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao tentar editar os dados da marca!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return error.response;
      });
  };

  const fetchDeleteBrand = async (
    brandId: number
  ): Promise<IBrandDeleteResponse> => {
    return await store
      .dispatch("admin-brands/fetchDeleteBrand", brandId)
      .then((response: IBrandDeleteResponse): IBrandDeleteResponse => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Marca excluída com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        return response;
      })
      .catch((data: any) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title:
              data.response.data.Errors[0] || "Erro ao tentar excluir Marca",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return data;
      });
  };

  const fetchActivateBrand = async (
    brand: IBrandWrite
  ): Promise<IBrandUpdateResponse> => {
    return await store
      .dispatch("admin-brands/fetchActivateBrand", brand)
      .then((response: IBrandUpdateResponse): IBrandUpdateResponse => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: `Marca ${
                brand.ativo ? "ativada" : "inativada"
              } com sucesso!`,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        return response;
      })
      .catch((error: XMLHttpRequest): any => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: `${error.response.data.Errors[0]}`,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return error;
      });
  };

  return {
    fetchCreateBrand,
    fetchListBrands,
    fetchUpdateBrand,
    fetchGetBrandById,
    fetchDeleteBrand,
    fetchActivateBrand,
  };
}
