




















































































































































































import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BPagination,
  BTable,
  BCardText,
  BvTableFieldArray,
} from "bootstrap-vue";
import { AvButton, AvExportButton } from "@/components";
import vSelect from "vue-select";
import { Ref, ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import BrandEditHandler from "./brands-edit-handler/BrandEditHandler.vue";
import SegmentEditHandler from "./segment-edit-handler/SegmentEditHandler.vue";
import { Component, Vue } from "vue-property-decorator";
import { AvSkeletonTable } from "@/components/av-skeleton";
import ENUMS from "@/enums";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import useBrandsHandler from "./brands-edit-handler/useBrandsHandler";

// Interfaces
import {
  IBrandDeleteResponse,
  IBrandRead,
} from "@core/services/interfaces/vehicle/brands/IBrandService";

@Component({
  name: "Brands",
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BTable,
    BCardText,
    SegmentEditHandler,
    AvSkeletonTable,
    AvButton,
    AvExportButton,
    BrandEditHandler,
    vSelect,
  },
  directives: {
    Ripple,
  },
})
export default class BrandsTab extends Vue {
  // Data
  $ENUMS = ENUMS;
  brandsList: IBrandRead[] = [];
  filterOn: IBrandRead[] = [];
  loading: boolean = true;
  isBrandSidebarActive: Ref<boolean> = ref(false);
  useBrandsHandler = useBrandsHandler(this);
  perPage: number = 10;
  perPageOptions: Array<number> = [10, 25, 50, 100];
  currentPage: number = 1;
  display: number = 0;
  total: number = 0;
  searchQuery = "";
  sortBy = "Nome";
  isSortDirDesc = false;
  brandId: number | null = null;
  brandDeleteId: number | null = null;
  modalConfirmShow: boolean = false;
  loadingDelete: boolean = false;
  $refs = {
    refListTable: {} as any,
  };

  statusOptions: Array<{ label: string; value: boolean }> = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  tableColumns: BvTableFieldArray = [
    { key: "Ordem", label: "Ordem", sortable: true },
    { key: "Nome", label: "Nome", sortable: true },
    { key: "Codigo", label: "Cód. Fabricante", sortable: true },
    { key: "Ativo", label: "Ativo", sortable: true },
    { key: "actions", label: "Ação", sortable: true },
  ];

  // Computed
  get dataMeta() {
    const localItemsCount = this.brandsList.length;

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to:
        this.perPage * this.currentPage < this.display
          ? this.perPage * this.currentPage
          : this.display,
      of: this.display,
      total: this.total,
    };
  }

  //Lifecycle
  async created() {
    await this.refreshData();
  }

  // Methods
  async refreshData() {
    this.loading = true;
    const response = await this.useBrandsHandler.fetchListBrands();
    this.brandsList = response;
    this.display = this.brandsList.length;
    this.total = this.brandsList.length;
    this.loading = false;
  }

  onFiltered(filteredItems: Array<any>) {
    this.display = filteredItems.length;
    this.currentPage = 1;
  }

  clearSegmentData = () => {
    this.brandId = null;
  };

  handlerSidebarBrand(brandId: number | null): void {
    this.isBrandSidebarActive.value = true;
    this.brandId = brandId;
  }

  updateIsBrandSidebarActive = (val: boolean): void => {
    this.isBrandSidebarActive.value = val;
  };

  /**
   * Abre o modal de confirmação de exclusão da biblioteca
   */
  showConfirmationDelete(brandId: number) {
    this.modalConfirmShow = true;
    this.brandDeleteId = brandId;
  }

  /**
   * Solicita ao backend a exclusão de uma marca
   */
  deleteBrand() {
    if (this.brandDeleteId) {
      this.loadingDelete = true;
      this.useBrandsHandler
        .fetchDeleteBrand(this.brandDeleteId)
        .then((response: IBrandDeleteResponse) => {
          if (response.status == 200) {
            this.refreshData();
            this.brandDeleteId = null;
            this.modalConfirmShow = false;
          }
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    }
  }

  getBrands() {
    return new Promise((resolve, reject) => {
      try {
        const data = this.$refs.refListTable.filteredItems.map(
          (brand: IBrandRead) => ({
            Ordem: brand.Ordem,
            Nome: brand.Nome,
            "Cód. Fabricante": brand.Codigo,
            Ativo: brand.Ativo ? "Ativo" : "Inativo",
          })
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
