import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useModel } from "@/services";

// Interfaces
import {
  IBodyListModels,
  IModelCreate,
  IModelCreateResponse,
  IModelUpdate,
  IModelUpdateResponse,
  IResponseGetModelById,
  IResponseListModels,
} from "@core/services/interfaces/vehicle/models/IModelService";

export default function useModelHandler(ctx: Vue) {
  const fetchCreateModel = async (
    model: IModelCreate
  ): Promise<IModelCreateResponse> => {
    return useModel
      .requestCreate(model)
      .then((response): IModelCreateResponse => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Modelo criado com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });

        return response;
      })
      .catch((response) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao criar Modelo!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response;
      });
  };

  const fetchListModels = async (
    paginar: boolean,
    modelName?: string,
    perPage?: number,
    currentPage?: number,
    statusFilter?: boolean | null,
    fabricanteFilter?: string | null
  ): Promise<IResponseListModels> => {
    let body: IBodyListModels = {
      paginate: paginar,
      draw: currentPage,
      length: perPage,
      start: currentPage,
    };

    if (typeof statusFilter == "boolean" || fabricanteFilter || modelName) {
      body.userdata = {};
    }

    if (typeof statusFilter == "boolean") {
      if (body.userdata) {
        body.userdata.Ativo = statusFilter;
      }
    }

    if (fabricanteFilter) {
      if (body.userdata) {
        body.userdata.FabricanteId = fabricanteFilter;
      }
    }

    if (modelName) {
      if (body.userdata) {
        body.userdata.Nome = modelName;
      }
    }

    return useModel
      .requestList(body)
      .then((response) => {
        return response;
      })
      .catch((response) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Um erro ocorreu ao buscar a lista de Modelos!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return response;
      });
  };

  const fetchUpdateModel = async (
    model: IModelUpdate
  ): Promise<IModelUpdateResponse> => {
    return useModel
      .requestUpdate(model)
      .then((response): IModelUpdateResponse => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Modelo atualizado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          ctx.$emit("refresh-data");
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        return response;
      })
      .catch((response) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ao atualizar o modelo!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response;
      });
  };

  const fetchGetModelById = async (
    modelId: number
  ): Promise<IResponseGetModelById> => {
    return useModel
      .requestGetById(modelId)
      .then((response): IResponseGetModelById => {
        return response;
      })
      .catch((error: XMLHttpRequest) => {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao tentar editar os dados do modelo!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return error.response;
      });
  };

  return {
    fetchCreateModel,
    fetchListModels,
    fetchUpdateModel,
    fetchGetModelById,
  };
}
