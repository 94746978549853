

























































































































































































































import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import ENUMS from "@/enums";
import { Ref, ref } from "@vue/composition-api";
import {
  BCard,
  BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import SegmentEditHandler from "./segment-edit-handler/SegmentEditHandler.vue";

// Services
import useSegmentHandler from "./segment-edit-handler/useSegmentHandler";

// Interfaces
import {
  IResponseGetSegmentTypes,
  IResponseListSegment,
  ISegment,
  ISegmentDeleteResponse,
  ISegmentUpdateResponse,
} from "@/@core/services/interfaces/vehicle/segment/ISegmentService";
import { BvTableFieldArray } from "bootstrap-vue";

@Component({
  name: "SegmentsTab",
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormInput,
    BPagination,
    SegmentEditHandler,
    AvSkeletonTable,
    AvButton,
    AvExportButton,
    BCardText,
    vSelect,
  },
  directives: {
    Ripple,
  },
})
export default class SegmentsTab extends Vue {
  // Data
  $ENUMS = ENUMS;
  segmentsList: ISegment[] = [];
  filterOn: ISegment[] = [];
  loading: boolean = true;
  isSegmentSidebarActive: Ref<boolean> = ref(false);
  statusOptions: Array<{ label: string; value: boolean }> = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];
  typeOptions: Array<{ label: string; value: number }> = [];
  // Table Handlers
  tableColumns: BvTableFieldArray = [
    { key: "Codigo", label: "Codigo", sortable: true },
    { key: "Nome", label: "Nome", sortable: true },
    { key: "Tipo", label: "Tipo", sortable: true },
    { key: "Escopo", label: "Escopo", sortable: true },
    { key: "Modelos", label: "Modelos Relacionados" },
    { key: "Ativo", label: "Ativo", sortable: true },
    { key: "actions", label: "Ação" },
  ];
  useSegmentHandler = useSegmentHandler(this);

  perPage: number = 10;
  perPageOptions: Array<number> = [10, 25, 50, 100];
  currentPage: number = 1;
  display: number = 0;
  total: number = 0;
  sortBy = "";
  isSortDirDesc = "";
  searchQuery = "";
  segmentId: number | null = null;
  modalConfirmShowActivate: boolean = false;
  segmentDeletId: number | null = null;
  modalConfirmShow: boolean = false;
  loadingDelete: boolean = false;
  segmentActivateId: number | null = null;
  loadingActivate: boolean = false;
  activeStatus: boolean = true;
  segmentName: string = "";
  segmentCode: number = 0;
  segmentTipo: string = "";
  segmentEscopo: boolean | null = null;
  $refs = {
    refListTable: {} as any,
  };

  // Computed
  get dataMeta() {
    const localItemsCount = this.segmentsList.length;

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to:
        this.perPage * this.currentPage < this.display
          ? this.perPage * this.currentPage
          : this.display,
      of: this.display,
      total: this.total,
    };
  }

  //Lifecycle
  created() {
    this.refreshData();
  }

  // Methods
  refreshData() {
    this.loading = true;
    this.useSegmentHandler
      .fetchGetSegmentTypes()
      .then((response: IResponseGetSegmentTypes) => {
        this.typeOptions = response.data.Data.map((type) => {
          return {
            label: type.Value,
            value: type.Key,
          };
        });
      });

    this.useSegmentHandler
      .fetchListSegments()
      .then((response: IResponseListSegment) => {
        this.segmentsList = response.data.Data;
        this.loading = false;
        this.display = this.segmentsList.length;
        this.total = this.segmentsList.length;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onFiltered(filteredItems: Array<any>) {
    this.display = filteredItems.length;
    this.currentPage = 1;
  }

  clearSegmentData = () => {
    this.segmentId = null;
  };

  handlerSidebarSegment(segmentId: number | null): void {
    this.isSegmentSidebarActive.value = true;
    this.segmentId = segmentId;
  }

  updateIsSegmentSidebarActive = (val: boolean): void => {
    this.isSegmentSidebarActive.value = val;
  };

  showConfirmationActivate(
    segmentId: number,
    nome: string,
    codigo: number,
    ativo: boolean,
    tipo: string,
    escopo: boolean | null
  ) {
    this.segmentActivateId = segmentId;
    this.segmentName = nome;
    this.activeStatus = ativo;
    this.segmentCode = codigo;
    this.segmentTipo = tipo;
    this.segmentEscopo = escopo;
    this.modalConfirmShowActivate = true;
  }

  activateSegment() {
    if (this.segmentActivateId) {
      this.loadingActivate = true;
      let segment = {
        id: this.segmentActivateId,
        ativo: !this.activeStatus,
        nome: this.segmentName,
        codigo: this.segmentCode,
        tipo: this.segmentTipo,
        escopo: this.segmentEscopo,
      };
      this.useSegmentHandler
        .fetchUpdateSegment(segment)
        .then((response: ISegmentUpdateResponse) => {
          if (response.data.Status == 200 || response.data.Status == 201) {
            this.refreshData();
            this.segmentActivateId = null;
            this.modalConfirmShowActivate = false;
          }

          this.loadingActivate = false;
        });
    }
  }

  showConfirmationDelete(segmentId: number) {
    this.modalConfirmShow = true;
    this.segmentDeletId = segmentId;
  }

  deleteSegment() {
    if (this.segmentDeletId) {
      this.loadingDelete = true;
      this.useSegmentHandler
        .fetchDeleteSegment(this.segmentDeletId)
        .then((response: ISegmentDeleteResponse) => {
          if (response.data.Status == 200 || response.data.Status == 201) {
            this.refreshData();
            this.segmentDeletId = null;
            this.modalConfirmShow = false;
          }

          this.loadingDelete = false;
        });
    }
  }

  getSegments() {
    return new Promise((resolve, reject) => {
      try {
        const data = this.$refs.refListTable.filteredItems.map(
          (segment: ISegment) => ({
            Nome: segment.Nome,
            Código: segment.Codigo,
            Ativo: segment.Ativo ? "Ativo" : "Inativo",
            Tipo: segment.Tipo,
            Modelos: segment.Modelos,
          })
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
