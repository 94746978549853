















































































































































































































































































































































































import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BAvatar,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { AvSkeletonInput } from "@/components/av-skeleton";
import { ref, Ref } from "@vue/composition-api";
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import formValidation from "@core/comp-functions/forms/form-validation";
import AvButton from "@/components/av-button/AvButton.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

// Services
import { useNomenclature } from "@/services";

// Interfaces
import {
  INomenclature,
  INomenclatureUpdate,
} from "@core/services/interfaces/vehicle/nomenclature/INomenclatureService";
import { IModelUpdate } from "@/@core/services/interfaces/vehicle/models/IModelService";

@Component({
  name: "NomenclatureEdit",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    AvButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    AvSkeletonInput,
  },
  directives: {
    Ripple,
  },
})
export default class NomenclatureEdit extends Vue {
  @Prop({ required: true }) modeloId!: string;
  @Prop({ required: true }) segmentAssociadoOptions!: Array<any>;
  @Prop({ required: true }) segmentMercadoOptions!: Array<any>;
  @Prop({ required: true, default: false }) fabricanteOptions!: Array<{
    label: string;
    value: number;
  }>;
  @Prop({ required: true, default: false }) categoryOptions!: Array<{
    label: string;
    value: number;
  }>;
  @Prop({ required: true, default: false }) modelOptions!: Array<{
    label: string;
    value: string;
    key: number;
  }>;
  required = required;
  loading: Ref<boolean> = ref(false);
  isSavingChanges: boolean = false;
  modelLocal: IModelUpdate = {} as IModelUpdate;
  nomenclatureLocal: INomenclature = {} as INomenclature;
  nomenclatureUpdate: INomenclatureUpdate = {} as INomenclatureUpdate;

  statusOptions = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  resetSegmentLocal() {
    this.nomenclatureLocal.ModeloId = this.modeloId;
  }

  clearForm() {
    this.nomenclatureLocal = {} as INomenclature;
  }
  // useSegmentHandler = useSegmentHandler(this);
  formValidation = formValidation(this.resetSegmentLocal, this.clearForm);

  // Computeds
  get isLoading(): boolean {
    return this.loading.value;
  }

  @Watch("modeloId", { immediate: true })
  reset() {
    if (typeof this.modeloId == "number") {
      this.clearForm();
    } else {
      this.clearForm();
    }
  }

  @Watch("isSidebarActive")
  async handleSidebar(val: boolean) {
    this.clearForm();
    if (!val) {
      setTimeout(() => {}, 350);
    }
  }

  created() {
    this.loadNomenclatureById();
  }

  limitDigits(event: KeyboardEvent) {
    const keyCode = event.keyCode || event.which;
    const inputValue = String.fromCharCode(keyCode);
    const newValue = (this.nomenclatureLocal.Codigo || "") + inputValue;

    if (newValue.length > 10) {
      event.preventDefault();
      return;
    }
  }

  limitCilindradas(event: KeyboardEvent) {
    const keyCode = event.keyCode || event.which;
    const inputValue = String.fromCharCode(keyCode);
    const newValue = (this.nomenclatureLocal.Cilindradas || "") + inputValue;

    if (newValue.length > 10) {
      event.preventDefault();
      return;
    }
  }

  async loadNomenclatureById() {
    this.loading.value = true;
    await useNomenclature
      .requestList({
        draw: 0,
        start: 0,
        length: 1,
        userdata: {
          Id: this.modeloId,
        },
      })
      .then((response) => {
        this.nomenclatureLocal = response.data.data[0];
        //Fazendo essa checagem, pois SegmentoAssociado pode ser nulo, no caso de não existir para a nomenclatura
        this.nomenclatureLocal.SegmentoAssociado =
          this.nomenclatureLocal.SegmentoAssociado || "";

        this.loading.value = false;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar Nomenclaturas!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  async onSubmit() {
    this.loading.value = true;
    this.isSavingChanges = true;
    this.nomenclatureUpdate = {
      ModeloNome: this.nomenclatureLocal.Modelo,
      nome: this.nomenclatureLocal.Nome,
      nomeOriginal: this.nomenclatureLocal.DescricaoOriginal,
      fabricanteId: this.nomenclatureLocal.FabricanteId,
      id: this.nomenclatureLocal.Id,
      cilindradas: this.nomenclatureLocal.Cilindradas,
      categoriaVeiculoId: this.nomenclatureLocal.CategoriaVeiculoId,
      codigoRenavam: this.nomenclatureLocal.CodigoRenavam,
      ModeloCodigo: this.nomenclatureLocal.ModeloCodigo,
      ModeloId: this.nomenclatureLocal.ModeloId,
      ativo: this.nomenclatureLocal.Ativo,
      codigo: this.nomenclatureLocal.Codigo,
      segmentoId: this.nomenclatureLocal.SegmentoId,
      segmentoAssociadoId: this.nomenclatureLocal.SegmentoAssociadoId,
      potencia: this.nomenclatureLocal.Potencia,
      capacidadeCarga: this.nomenclatureLocal.CapacidadeCarga,
      capacidadePassageiro: this.nomenclatureLocal.CapacidadePassageiro,
    };
    await useNomenclature
      .requestUpdate(this.nomenclatureUpdate)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Modelo atualizado com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao atualizar Modelo!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    this.$emit("updateIsSidebarActive", false);
    this.$emit("refreshData");
    this.clearForm();
    this.isSavingChanges = false;
    this.loading.value = false;
  }

  // Functions
  /**
   * Abrir ou fechar o sidebar de edição/criação
   */
  toggleSidebarHandler = (val: boolean): void => {
    this.$emit("updateIsSidebarActive", val);
  };

  /**
   * Mapeando o valor do select de um objeto <{label, value}> para o seu valor "value"
   */
  // value deve ter o tipo any, pois por vezes será booleano, outras vezes string ou ainda número

  reduceOptionsValue = (val: { label: string; value: any }): any => {
    return val.value;
  };
}
