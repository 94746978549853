

















import { BTabs, BTab } from "bootstrap-vue";
import SegmentsTab from "./SegmentsTab.vue";
import BrandsTab from "./BrandsTab.vue";
import ModelsTab from "./ModelsTab.vue";
import VersionsTab from "./VersionsTab.vue";
import NomenclaturesTab from "./NomenclaturesTab.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Nomenclature",
  components: {
    SegmentsTab,
    BrandsTab,
    ModelsTab,
    VersionsTab,
    NomenclaturesTab,
    BTabs,
    BTab,
  },
})
export default class Nomenclature extends Vue {}
