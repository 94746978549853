








































































































































































import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BCardHeader,
  BCardBody,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
    NomenclatureFilter,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
  },
})
export default class NomenclatureFilter extends Vue {
  @Prop({ required: true }) fabricanteOptions!: Array<any>;
  @Prop({ required: true }) categoryOptions!: Array<any>;
  @Prop({ required: true }) segmentMercadoOptions!: Array<any>;
  @Prop({ required: true }) segmentAssociadoOptions!: Array<any>;
  @Prop({ required: true }) modelOptions!: Array<any>;
  @Prop({ required: true }) confirmationOptions!: Array<any>;
  @Prop({ required: true }) statusOptions!: Array<any>;
  @Prop({ required: true }) loading!: boolean;

  model: string = "";
  brand: string = "";
  category: string = "";
  associatedSegment: string = "";
  marketSegment: string = "";
  code: string = "";
  cilindradas: string = "";
  originalDescription: string = "";
  newRegistration: string = "";
  active: string = "";

  clearAllFilters() {
    this.model = "";
    this.brand = "";
    this.category = "";
    this.associatedSegment = "";
    this.marketSegment = "";
    this.code = "";
    this.cilindradas = "";
    this.originalDescription = "";
    this.newRegistration = "";
    this.active = "";
    this.$emit("clearAllFilters");
  }

  valueController(
    field: string,
    val: { label: string | number; value: string | number }
  ) {
    if (val != null) {
      if (val.value != null) {
        this.$emit("update:" + field, val.value);
      } else {
        this.$emit("update:" + field, val);
      }
    } else {
      this.$emit("update:" + field, null);
    }
  }
}
