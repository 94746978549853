







































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ModelListFilters from "@/views/admin-panel/vehicle-version/model-edit-handler/ModelListFilters.vue";
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import { Ref, ref } from "@vue/composition-api";
import ModelEditHandler from "./model-edit-handler/ModelEditHandler.vue";
import useBrandsHandler from "@/views/admin-panel/vehicle-version/brands-edit-handler/useBrandsHandler";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useModel } from "@/services";

// Interfaces
import {
  IBodyListModels,
  IModel,
  IResponseListModels,
} from "@/@core/services/interfaces/vehicle/models/IModelService";
import { IBrandRead } from "@core/services/interfaces/vehicle/brands/IBrandService";

@Component({
  name: "ModelsTab",
  components: {
    AvSkeletonTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    AvButton,
    AvExportButton,
    vSelect,
    ModelEditHandler,
    ModelListFilters,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
})
export default class ModelsTab extends Vue {
  // Data
  useBrandsHandler = useBrandsHandler(this);
  modelId: number = 0;
  paginar: boolean = true;
  models: Array<IModel> = [];
  perPage: number = 10;
  isSideBarActive: Ref<boolean> = ref(false);
  total: number = 0;
  display: number = 0;
  currentPage: number = 1;
  perPageOptions: Array<number> = [10, 25, 50, 100];
  modelName: string = "";
  sortBy: string = "";
  isSortDirDesc: boolean = false;
  statusFilter = null;
  fabricanteFilter = null;
  loading: boolean = true;
  firstRequest: boolean = true;
  fabricanteOptions: Array<{ label: string; value: number }> = [];

  tableColumns = [
    { key: "Fabricante.Ordem", label: "Ordem", sortable: true },
    { key: "Fabricante.Nome", label: "Fabricante", sortable: true },
    { key: "Nome", label: "Nome", sortable: true },
    { key: "Ativo", label: "Ativo" },
    { key: "actions", label: "Ações" },
  ];

  statusOptions = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  // Computeds
  get dataMeta() {
    const localItemsCount = this.models.length;

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to: this.perPage * this.currentPage,
      of: this.display,
      total: this.total,
    };
  }

  // LifeCycle
  created() {
    this.fetchListModels();
    this.fetchListFabricantes();
  }

  // Watchs
  @Watch("perPage")
  @Watch("currentPage")
  @Watch("fabricanteFilter")
  @Watch("statusFilter")
  fetchListModels() {
    this.loading = true;

    let body: IBodyListModels = {
      paginate: this.paginar,
      draw: this.currentPage,
      length: this.perPage,
    };

    if (
      typeof this.statusFilter == "boolean" ||
      this.fabricanteFilter ||
      this.modelName != ""
    ) {
      body.userdata = {};
    }

    if (typeof this.statusFilter == "boolean") {
      if (body.userdata) {
        body.userdata.Ativo = this.statusFilter;
      }
    }

    if (this.fabricanteFilter) {
      if (body.userdata) {
        body.userdata.FabricanteId = this.fabricanteFilter;
      }
    }

    if (this.modelName != "") {
      if (body.userdata) {
        body.userdata.Nome = this.modelName;
      }
    }

    useModel
      .requestList(body)
      .then((response) => {
        this.display = response.data.recordsFiltered;
        this.total = response.data.recordsTotal;
        this.models = response.data.data;
      })
      .catch((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Um erro ocorreu ao buscar a lista de Modelos!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return response;
      })
      .finally(() => {
        this.loading = false;
        this.firstRequest = false;
      });
  }

  // Methods
  updateIsSidebarActive(val: boolean) {
    this.modelId = 0;
    this.isSideBarActive.value = val;
  }

  fetchListFabricantes() {
    this.useBrandsHandler
      .fetchListBrands()
      .then((fabricantes: Array<IBrandRead>) => {
        this.fabricanteOptions = fabricantes.map(
          (option: IBrandRead): { label: string; value: number } => {
            return {
              label: option.Nome,
              value: option.Id ? option.Id : -1,
            };
          }
        );
      });
  }

  handleUpdateId(modelId: number) {
    this.modelId = modelId;
    this.isSideBarActive.value = true;
  }

  async getModels() {
    return new Promise((resolve, reject) => {
      let body: IBodyListModels = {
        paginate: false,
        draw: 1,
        length: 500000,
      };

      if (
        typeof this.statusFilter == "boolean" ||
        this.fabricanteFilter ||
        this.modelName != ""
      ) {
        body.userdata = {};
      }

      if (typeof this.statusFilter == "boolean") {
        if (body.userdata) {
          body.userdata.Ativo = this.statusFilter;
        }
      }

      if (this.fabricanteFilter) {
        if (body.userdata) {
          body.userdata.FabricanteId = this.fabricanteFilter;
        }
      }

      if (this.modelName != "") {
        if (body.userdata) {
          body.userdata.Nome = this.modelName;
        }
      }

      useModel
        .requestList(body)
        .then((response: IResponseListModels) => {
          const data = response.data.data.map((model: IModel) => ({
            Ordem: model.Fabricante.Ordem,
            Fabricante: model.Fabricante.Nome,
            Nome: model.Nome,
            Ativo: model.Ativo ? "Ativo" : "Inativo",
          }));

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
