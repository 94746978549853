




import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "VersionsTab",
  components: {},
})
export default class VersionsTab extends Vue {}
